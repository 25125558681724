import React from 'react';

export const WaveServices = () => (
  <svg width="100%" height="100%" viewBox="0 0 1728 349" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_130_357)">
      <path
        d="M230 33.1066C184.762 2.94275 6.98786 18.8841 0 0H1728C1724.78 2.92897 1717.5 142.637 1651.5 88.4906C1582.94 32.2465 1489 241.957 1455 88.4906C1455 239.172 1492.97 206.751 1275.5 81.6836C1091.5 -24.1338 1319.28 282.668 1172.5 323.95C1055.08 356.975 934 233.603 948.5 68.3791C878.391 262.792 857.583 385.124 715.5 339.42C594.276 300.426 709.768 194.065 552 219.679C425.786 240.171 472 114.191 472 102.723C454.861 113.804 459.829 163.165 408.5 176.362C344.338 192.859 294.5 76.1143 230 33.1066Z"
        fill="#FBFBFB"
      />
      <path
        opacity="0.1"
        d="M230 28.7749C184.762 -1.38898 6.98786 14.5524 0 -4.33173H1728C1724.78 -1.40276 1717.5 138.305 1651.5 84.1589C1582.94 27.9148 1501.5 237.625 1455 88.8C1448.5 228.652 1492.97 202.42 1275.5 77.3519C1091.5 -28.4655 1319.28 278.336 1172.5 319.618C1055.08 352.644 948.5 231.746 948.5 68.6885C851 264.853 857.583 380.792 715.5 335.089C594.276 296.095 709.768 189.733 552 215.348C425.786 235.839 479 113.243 472 102.723C448.5 112.006 459.829 158.834 408.5 172.031C344.338 188.527 294.5 71.7826 230 28.7749Z"
        fill="black"
      />
      <path
        d="M230 14.5421C184.762 -15.6217 3.98786 17.0277 -3 -1.85648L1732 -17.9457C1728.78 -15.0167 1717.5 124.072 1651.5 69.9261C1582.94 13.682 1489 223.392 1455 69.9261C1435 218.442 1492.97 188.187 1275.5 63.1191C1091.5 -42.6983 1319.28 264.104 1172.5 305.385C1055.08 338.411 975 216.276 951 53.5275C842 242.266 857.583 366.559 715.5 320.856C594.276 281.862 709.768 175.5 552 201.115C425.786 221.607 485.5 112.624 473.5 99.6293C449 108.293 459.829 144.601 408.5 157.798C344.338 174.294 294.5 57.5498 230 14.5421Z"
        fill="#FBFBFB"
      />
    </g>
    <defs>
      <clipPath id="clip0_130_357">
        <rect width="1728" height="349" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
