import React from 'react';
import { StyledBottom, StyledContainer } from '../../../config/styled/shared';
import { Typography } from '../../../config/styled/typography';
import { Card } from '../../elements/card/card';
import { WaveServices } from '../../icons/WaveServices';
import { ServicesLine } from '../../icons/ServicesLine';
import { CardIcons } from '../../icons/CardIcons';
import { ServicesCard } from '../../icons/ServicesCard';
import * as S from './styles';

export const ServicesTemplate = () => {
  return (
    <>
      <StyledContainer css={{ zIndex: 6 }}>
        <S.PageTop>
          <S.Left>
            <Typography.XXL as="h1" responsive>
              Services
            </Typography.XXL>
            <S.ServicesLineHolder>
              <ServicesLine />
            </S.ServicesLineHolder>
          </S.Left>
          <S.Right>
            <p>
              From start to finish, we offer a seamless, efficient and well-managed process for you and your clients.
            </p>
            <S.Break />
            <p>
              Our client's needs are always our top priority. We provide creative ideas, proper technical integrations,
              and data-driven marketing, all of which are implemented in-house and on-site.
            </p>
            <S.Break />
            <p>Bermond Management offers such marketing services as:</p>
            <S.Break />
            <ul>
              <li>marketing audit;</li>
              <li>comprehensive market analysis;</li>
              <li>consumer research;</li>
              <li>identification of possible risks;</li>
              <li>valuation of opponents;</li>
              <li>development of integrated marketing strategies;</li>
              <li>development and protection of business plans;</li>
              <li>compilation of databases. </li>
            </ul>
          </S.Right>
        </S.PageTop>
      </StyledContainer>
      <StyledBottom>
        <StyledContainer css={{ position: 'relative' }}>
          <S.ServicesCardHolder>
            <ServicesCard />
          </S.ServicesCardHolder>
        </StyledContainer>
        <WaveServices />
        <StyledContainer>
          <S.Cards>
            <Card
              title="Advertising"
              content="Your ads need to present your brand in a fresh and appealing light while also reaching target demographic. We can help with both."
              link="/advertising"
              iconClass="ads"
              icon={CardIcons.ADSIcon}
            />
            <Card
              title="Marketing"
              content="We can help define your business objectives and create the best processes and marketing strategies to achieve those goals."
              link="/marketing"
              iconClass="marketing"
              icon={CardIcons.MarketingIcon}
            />
            <Card
              title="Branding"
              content="We employ a range of techniques that we've refined over the years. We know how to diagnose a challenge to determine a way forward. The key is to simplify complexity in order to align minds and focus resources into the right areas."
              link="/branding"
              iconClass="branding"
              icon={CardIcons.BrandingIcon}
            />
            <Card
              title="STRATEGY & INSIGHTS"
              content="We employ a range of techniques that we've refined over the years. We know how to diagnose a challenge to determine a way forward. The key is to simplify complexity in order to align minds and focus resources into the right areas."
              iconClass="sii"
              icon={CardIcons.SIIcon}
            />
            <Card
              title="PARTNERSHIPS"
              content="By focusing on long term relationships we are able to gain a deeper understanding of organisations as they evolve, and allows us to continuously evaluate and improve marketing products and leads to the cycles between full redesigns being extended."
              iconClass="prtnrs"
              icon={CardIcons.PRTNRSIcon}
            />
          </S.Cards>
        </StyledContainer>
      </StyledBottom>
    </>
  );
};
