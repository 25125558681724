import React from 'react';
import { ArrowIcon } from '../../icons/ArrowIcon';
import * as S from './card.styles';

export const Card = ({ title, content, icon: Icon, iconClass, link }) => {
  return (
    <S.Card>
      <S.Title>{title.toUpperCase()}</S.Title>
      <S.Content>{content}</S.Content>
      {link && (
        <S.Button to={link}>
          <ArrowIcon color="#ffffff" />
          Learn More
        </S.Button>
      )}
      <S.Icon className={iconClass}>
        <Icon />
      </S.Icon>
    </S.Card>
  );
};
