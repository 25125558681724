import React from 'react';

export const ServicesLine = () => (
  <svg width="569" height="692" viewBox="0 0 569 692" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M142 644C114 666.5 105 684 105 684C105 684 98.4999 665 64.9999 651M568.913 3C-31.587 19 -109.087 23.791 126.413 29.5C645.792 42.0908 436.927 60.5813 324.913 65C71.413 75 81.4128 209 99.4128 656"
      stroke="#FF8310"
      strokeWidth="6"
    />
    <path
      d="M136.913 644C108.913 666.5 99.9128 684 99.9128 684C99.9128 684 93.4128 665 59.9128 651"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);
