import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { mq } from '../../../config/styled/media';

export const Card = styled.div`
  color: #ffffff;
  padding: 41px;
  border: 2px solid #fbfbfb;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .ads {
    top: 0;
    transform: translateY(25%);
  }
  .marketing {
    top: 0;
    transform: translateY(100%);
    ${mq.lt.lg} {
      transform: translateY(50%);
    }
  }
  .branding {
    top: 0;
    transform: translateY(160%);
  }
  .prtnrs {
    top: 0;
    transform: translateY(55%);
  }
  .sii {
    bottom: 0;
    transform: translateX(20%);
    ${mq.lt.md} {
      svg {
        width: 300px;
      }
      top: 10%;
      left: 60%;
      transform: translateX(-50%) rotate(-15deg);
    }
  }
  ${mq.lt.lg} {
    padding: 30px;
  }
  ${mq.lt.md} {
    padding: 24px;
  }
`;

export const Icon = styled.i`
  position: absolute;
`;

export const Title = styled.h2`
  font-size: 5.6rem;
  line-height: 6.7rem;
  text-align: center;
  font-weight: 400;
  margin: 0;
  @media screen and (max-width: 1400px) {
    font-size: 4.5rem;
  }
  ${mq.lt.lg} {
    font-size: 3.8rem;
    line-height: 4.9rem;
  }
`;

export const Content = styled.p`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 40px 0 40px 0;
  font-weight: 400;
  ${mq.lt.md} {
    margin-bottom: 24px;
  }
`;

export const Button = styled(Link)`
  background: transparent;
  color: #ffffff;
  border: 1px solid #8b8b8b;
  border-radius: 16px;
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.4rem;
  padding: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
  ${mq.lt.sm} {
    width: 100%;
    justify-content: center;
  }
  svg {
    margin-right: 14px;
  }
`;
