import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';

export const Break = styled.hr`
  visibility: hidden;
  height: 43px;
  ${mq.lt.md} {
    height: 16px;
  }
`;

export const Cards = styled.div`
  display: grid;
  gap: 64px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 'a a b b c c' 'd d d f f f';
  ${mq.lt.lg} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'a b' 'c d' 'f f';
  }
  ${mq.lt.md} {
    gap: 30px;
    grid-template-columns: 1fr;
    grid-template-areas: 'a' 'b' 'c' 'd' 'f';
  }
  > * {
    &:nth-of-type(1) {
      grid-area: a;
    }
    &:nth-of-type(2) {
      grid-area: b;
    }
    &:nth-of-type(3) {
      grid-area: c;
    }
    &:nth-of-type(4) {
      grid-area: d;
    }
    &:nth-of-type(5) {
      grid-area: f;
    }
  }
`;

export const PageTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 84px;
  margin-top: 99px;
  overflow: visible;
  z-index: 4;
  ${mq.lt.lg} {
    flex-direction: column;
    gap: 16px;
    margin: 24px 0 36px 0;
  }
`;

export const Left = styled.div`
  position: relative;
  flex-basis: 40%;
  margin-top: 40px;
  ${mq.lt.lg} {
    margin-top: 0px;
    flex-basis: 50%;
  }
`;

export const Right = styled.div`
  z-index: 3;
  > *,
  * {
    font-size: 3.6rem;
    line-height: 4.3rem;
    font-weight: 400;
    margin-bottom: 0;
    ${mq.lt.lg} {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ${mq.lt.lg} {
    flex-basis: 50%;
  }
`;

export const ServicesLineHolder = styled.div`
  position: absolute !important;
  top: 100%;
  transform: translateY(-45px);
  overflow: visible;
  ${mq.lt.lg} {
    svg {
      width: 300px;
      height: 420px;
    }
  }
  ${mq.lt.lg} {
    display: none;
  }
`;

export const ServicesCardHolder = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  transform: translate(150px, 140px);
  left: -5%;
  ${mq.lt.lg} {
    left: 100%;
    transform: translate(-100%, 50%);
    svg {
      width: 270px;
      height: 380px;
    }
  }
  ${mq.lt.md} {
    svg {
      width: 170px;
      height: 280px;
    }
  }
`;
