import React from 'react';
import { Layout } from '../components/layout';
import { ServicesTemplate } from '../components/templates/services';

const ServicesPage = () => {
  return (
    <Layout>
      <ServicesTemplate />
    </Layout>
  );
};

export const Head = () => <title>Bermond Management</title>;

export default ServicesPage;
