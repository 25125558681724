import React from 'react';

export const ServicesCard = () => (
  <svg width="598" height="683" viewBox="0 0 598 683" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="295.995"
      y="13.6811"
      width="332.628"
      height="564.628"
      transform="rotate(30 295.995 13.6811)"
      fill="#50A6DB"
    />
    <g clipPath="url(#clip0_312_1366)">
      <path
        d="M231.544 252.036L363.65 328.307C365.334 329.28 367.334 329.557 369.22 329.08L435.781 312.264C436.566 312.067 437.236 311.559 437.642 310.857L441.373 304.395C441.654 303.908 441.731 303.326 441.585 302.781C441.439 302.236 441.082 301.771 440.594 301.489L308.488 225.217C306.802 224.244 304.804 223.967 302.916 224.443L236.356 241.258C235.57 241.457 234.9 241.964 234.495 242.665L230.763 249.129C230.176 250.146 230.524 251.447 231.541 252.034L231.544 252.036Z"
        fill="white"
      />
      <path
        d="M415.875 344.313L397.828 333.893L372.084 340.399C367.253 341.634 362.129 340.922 357.818 338.414L247.877 274.94L211.635 284.087C210.848 284.281 210.175 284.789 209.773 285.492L206.039 291.959C205.458 292.975 205.804 294.271 206.817 294.861L338.926 371.135C340.615 372.1 342.611 372.375 344.495 371.897L411.057 355.087C411.843 354.894 412.516 354.386 412.918 353.684L416.652 347.216C417.234 346.199 416.887 344.904 415.874 344.314L415.875 344.313Z"
        fill="white"
      />
      <path
        d="M391.149 387.139L373.102 376.72L347.358 383.226C342.525 384.441 337.41 383.73 333.092 381.24L223.13 317.754L186.918 326.893C186.129 327.098 185.455 327.611 185.045 328.317L181.323 334.765C181.04 335.252 180.963 335.831 181.109 336.376C181.255 336.921 181.611 337.384 182.101 337.665L314.211 413.939C315.892 414.911 317.884 415.191 319.768 414.722L386.329 397.912C387.113 397.714 387.785 397.207 388.191 396.508L391.925 390.04L391.924 390.041C392.506 389.024 392.159 387.73 391.147 387.139L391.149 387.139Z"
        fill="white"
      />
      <path
        d="M366.424 429.966L348.357 419.535L322.644 426.031C317.81 427.264 312.687 426.56 308.366 424.067L198.403 360.58L162.192 369.719C161.409 369.918 160.737 370.424 160.331 371.124L156.597 377.592L156.597 377.59C156.016 378.608 156.362 379.902 157.375 380.492L289.485 456.766C291.166 457.738 293.158 458.018 295.041 457.549L361.603 440.739C362.393 440.533 363.067 440.02 363.476 439.315L367.198 432.868C367.482 432.38 367.559 431.8 367.413 431.256C367.267 430.711 366.911 430.247 366.421 429.966L366.424 429.966Z"
        fill="white"
      />
    </g>
    <rect
      x="295.995"
      y="13.6811"
      width="332.628"
      height="564.628"
      transform="rotate(30 295.995 13.6811)"
      stroke="#008FE7"
      strokeWidth="20.0305"
    />
    <defs>
      <clipPath id="clip0_312_1366">
        <rect width="210.028" height="210.028" fill="white" transform="translate(260.548 197.54) rotate(30)" />
      </clipPath>
    </defs>
  </svg>
);
