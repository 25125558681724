import React from 'react';

const ADSIcon = () => (
  <svg width="226" height="106" viewBox="0 0 226 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.04479 100.148L48.2314 11.4795L55.0521 92.266M1.3396 66.8971L71.251 49.8455M106.207 23.4156L76.3666 101C116.58 88.4957 203.827 30.6625 114.733 13.1846M222.283 34.9254C228.677 10.6888 178.887 8.45123 174.112 34.9254C168.144 68.018 212.21 48.2082 210.773 67.7496C208.642 96.7373 146.83 111.657 157.061 83.9486"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);

const MarketingIcon = () => (
  <svg width="276" height="71" viewBox="0 0 276 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.49976 58.5922L19.069 15.7351L17.7052 48.8075L31.0016 12.6039V64.0717M37.6807 49.9278C38.3317 47.7394 45.5477 24.6572 49.0743 13.3897V40.549M33.7518 36.5698L59.6821 26.7477M69.897 12.211L58.8963 62.5001M72.3198 15.3541C79.2567 16.2708 91.1893 35.7102 72.3198 37.1591C62.0869 37.9449 83.5874 53.6602 76.344 63.2859M92.2914 10.2466L82.0764 64.0716M113.114 2.78186L90.327 37.9449L104.409 67.2147M119.007 8.2822L106.435 49.9278M122.15 9.85375L139.437 5.92492M117.829 30.2837L135.312 27.5335M109.578 51.8923L133.151 47.5705M148.867 12.8004L180.297 3.56761M161.439 15.3541C160.952 21.572 157.045 48.3279 155.153 60.9286M194.048 1.40674L186.976 57.7855M196.798 69.572L210.786 19.9164C212.33 33.0641 217.929 59.0785 217.929 57.8764C217.929 56.6743 221.943 21.5597 228.229 5.53203M274 12.0146C245.392 -11.518 205.811 48.5152 253.146 59.6999C267.981 63.2051 276.74 47.5939 270.342 31.8677C260.456 34.5716 254.794 34.4088 244.534 31.8677"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);

const BrandingIcon = () => (
  <svg width="289" height="50" viewBox="0 0 289 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M72.7061 26.9694C203.857 26.9092 439.217 -12.0064 143.213 9.49797C-152.79 31.0023 87.481 49.7666 246.953 45.3767"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);

const SIIcon = () => (
  <svg width="361" height="159" viewBox="0 0 361 159" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.0001 72.8598C69.4786 28.4651 135.912 -77.36 232.591 112.199C232.714 112.439 232.802 112.713 232.9 112.964C269.605 207.083 308.683 125.471 323.643 72.8599M299.756 77.0641C317.557 72.276 324.964 65.6602 324.964 65.6602C324.964 65.6602 324.978 66.6408 325.394 68.406M325.394 68.406C326.36 72.5166 329.501 80.8822 339.702 91.0282M325.394 68.406C322.069 70.6619 314.713 74.8816 302.319 78.2152M325.394 68.406C326.844 67.4218 327.528 66.8114 327.528 66.8114C327.528 66.8114 327.684 77.6764 342.266 92.1794M2 31.973L13.2548 44.1623M13.2548 44.1623L29 61.2148M13.2548 44.1623C21.143 31.1499 25.9687 29.923 27.669 31.5805M13.2548 44.1623C10.5576 48.6116 7.50235 54.4389 4.09155 61.9999M229.158 63.2397L240.45 71.3093M240.45 71.3093L256.248 82.5984M240.45 71.3093C244.873 59.4163 248.669 57.6225 250.345 58.6918M240.45 71.3093C238.938 75.3759 237.352 80.6232 235.753 87.3406M358 24.157C356 36.8475 349.27 46.9999 338.5 46.9999C327.73 46.9999 320.167 41.1427 319 24.157C319 11.5412 324.397 5.99985 335.167 5.99985C345.936 5.99985 358 11.5412 358 24.157Z"
      stroke="#FF8310"
      strokeWidth="4.7482"
    />
  </svg>
);

const PRTNRSIcon = () => (
  <svg width="207" height="67" viewBox="0 0 207 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M112.146 66.04L126.133 16.3844C127.677 29.5321 133.277 55.5465 133.277 54.3444C133.277 53.1423 137.291 18.0277 143.577 2M58.146 28L80.7698 25.7376M80.7698 25.7376L108.146 23M80.7698 25.7376L83.146 14M80.7698 25.7376L74.2247 58.0683C74.1724 58.3268 74.146 58.5898 74.146 58.8534V63M41.5735 9L30.5728 59.2891M43.9963 12.1431C50.9332 13.0598 62.8658 32.4992 43.9963 33.9481C33.7634 34.7339 55.2639 50.4492 48.0204 60.0749M14.0007 9L8.54333 33.9481M8.54333 33.9481L3 59.2891M8.54333 33.9481H16.4233C35.2928 32.4992 23.3602 13.0598 16.4233 12.1431M8.54333 33.9481H7.5M158.577 9L147.577 59.2891M161 12.1431C167.937 13.0598 179.87 32.4992 161 33.9481C150.767 34.7339 172.268 50.4492 165.024 60.0749M202.275 23.9205C202.275 12.0317 182.914 10.2484 177.775 21.7409C172.319 33.9405 191.493 32.8405 199.65 35.6111C205.483 37.5926 207.817 56.4165 175.15 52.4535"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);

export const CardIcons = {
  SIIcon,
  ADSIcon,
  BrandingIcon,
  MarketingIcon,
  PRTNRSIcon,
};
